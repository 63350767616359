import React from 'react'
import { Box, Flex } from 'reflexbox'
import LinkComponent from 'src/components/LinkComponent/LinkComponent'

const NotFound = () => (
  <Flex width={1} height="100vh" alignItems="center" justifyContent="center">
    <Flex
      textAlign="center"
      fontSize={'54px'}
      color="var(--yellow)"
      flexDirection="column"
    >
      <Box marginBottom={4} as="h1">
        Sidan kunde inte hittas
      </Box>
      <LinkComponent to={'/'}>Tillbaka till sajten</LinkComponent>
    </Flex>
  </Flex>
)

export default NotFound
